body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-size: cover;
}

/* Adjust for the safe area insets on iOS Safari */
body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@layer utilities {
  .full-safe-area {
    @apply fixed top-0 left-0 w-screen h-screen bg-cover bg-center;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.textColor {
  @apply text-gray-700 dark:text-gray-200;
}

.secondaryTextColor {
  @apply text-gray-600 dark:text-gray-300;
}

.background-shadow {
  @apply border-4 border-[rgba(3,15,64,1)] shadow-[3px_3px_0px_0px_rgba(3,15,64,1)] dark:border-[rgba(56,57,59,1)] dark:shadow-[3px_3px_0px_0px_rgba(56,57,59,1)];
}

.background-shadow-new {
  @apply border-4;
  border-color: var(--theme-border-color);
  box-shadow: 3px 3px 0px 0px var(--theme-border-color);
}

.background-hover {
  @apply sm:hover:shadow-none sm:hover:translate-x-1 sm:hover:translate-y-1 transition-all duration-300 transform;
}

.background-focus {
  @apply sm:focus:shadow-none sm:focus:translate-x-1 sm:focus:translate-y-1 transition-all duration-300 transform;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
